/* eslint-disable no-unused-vars */
import { useEffect } from 'react'
import {
  fetchUser
} from '../../features/user/userSlice'
import {
  fetchProfilePicture
} from '../../features/user/profilePictureSlice'

export const MyAccountManager = ({
  t,
  dispatch,
  userCallStatus,
  userProfilePictureStatus,
  userObj,
  userCallError,
  userProfilePictureData,
  LanguageSelectionManager,
  EditAccountManager,
  updateUser,
}) => {
  useEffect(() => {
    if (userCallStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userCallStatus, dispatch])

  useEffect(() => {
    if (userProfilePictureStatus === 'idle') {
      dispatch(fetchProfilePicture())
    }
  }, [userProfilePictureStatus, dispatch])

  const {
    editAccountComponent,
    showEditAccount,
    showProfileInfo,
    toggleEditAccount,
    setAlertVisible
  } = EditAccountManager({
    dispatch,
    userObj: userObj || {},
    t,
    updateUser,
  })

  const {
    languageSelectorComponent,
    setAlertVisible: setLanguageAlertVisible,
    showLanguageSelector,
    toggleLanguageSelector,
    languageOptions,
  } = LanguageSelectionManager({
    dispatch,
    userObj: userObj || {},
    t,
  })

  let fullName = ''
  if (userObj) {
    fullName = `${userObj.firstName ?? ''} ${userObj.lastName ?? ''}`
  }

  const preferredLanguage = `${userObj.preferredLanguage ?? ''}`

  const isPageLoading = !((userCallStatus === 'succeeded' || userCallStatus === 'failed')
            && (userProfilePictureStatus === 'succeeded'
                || userProfilePictureStatus === 'failed'))

  return {
    isPageLoading,
    userCallError,
    userObj,
    userProfilePictureData,
    fullName,
    preferredLanguage,
    languageSelectorComponent,
    setAlertVisible,
    showLanguageSelector,
    toggleLanguageSelector,
    languageOptions,
    editAccountComponent,
    showEditAccount,
    showProfileInfo,
    toggleEditAccount,
  }
}
