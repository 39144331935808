import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectUserObject, selectUserCallStatus, selectUserCallError, updateUser, fetchUser
} from '../user/userSlice'
import { selectProfilePictureStatus, fetchProfilePicture, selectProfilePictureImage } from '../user/profilePictureSlice'
import { EditAccountManager } from './EditAccountManager'

export function EditAccountContainer({ t }) {
  const dispatch = useDispatch()
  const userObj = useSelector(selectUserObject)
  const userCallStatus = useSelector(selectUserCallStatus)
  const userCallError = useSelector(selectUserCallError)
  const userProfilePictureData = useSelector(selectProfilePictureImage)
  const userProfilePictureStatus = useSelector(selectProfilePictureStatus)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  useEffect(() => {
    if (userCallStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userCallStatus, dispatch])

  useEffect(() => {
    if (userProfilePictureStatus === 'idle') {
      dispatch(fetchProfilePicture())
    }
  }, [userProfilePictureStatus, dispatch])

  useEffect(() => {
    setIsPageLoading(
      !((userCallStatus === 'succeeded' || userCallStatus === 'failed')
                && (userProfilePictureStatus === 'succeeded' || userProfilePictureStatus === 'failed'))
    )
  }, [userCallStatus, userProfilePictureStatus])

  useEffect(() => {
    if (userCallError) {
      setAlertMessage(userCallError)
      setShowAlert(true)
    }
  }, [userCallError])

  const {
    showEditAccount,
    toggleEditAccount,
    handleSubmit,
    userFields,
    validFields,
    validateChange,
    handleBlur,
    isFormValid,
    TextMaskCustom,
    setUserFields,
  } = EditAccountManager({
    dispatch, userObj, t, updateUser, setAlertMessage
  })

  return {
    isPageLoading,
    showAlert,
    alertMessage,
    showEditAccount,
    toggleEditAccount,
    handleSubmit,
    userFields,
    validFields,
    validateChange,
    handleBlur,
    isFormValid,
    TextMaskCustom,
    setUserFields,
    userProfilePictureData,
  }
}
