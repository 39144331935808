import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import styles from './Dashboard.module.scss'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import { ApplicationGrid } from '../../features/ApplicationGrid'
import { selectUserCallStatus } from '../../features/user/userSlice'

function DashboardDesktop({
  t, isPageLoading, userObjData, userAppsData, appGridIsLoading
}) {
  const userCallStatus = useSelector(selectUserCallStatus)
  return (
    <MarginWrapper>
      <div className={styles['dashboard-page']}>
        <div className={styles['welcome-header']}>
          <Typography
            className={styles['welcome-header']}
            sx={{
              fontFamily: 'JohnsonDisplay', fontWeight: 'bolder', color: 'black', lineHeight: '2', whiteSpace: 'nowrap', fontSize: 'calc(16px + 1vw)'
            }}
          >
            {`${t('WelcomeHeader')} ${userObjData.firstName || ''}! \n`}
          </Typography>
          <Typography
            className={styles['welcome-header']}
            sx={{
              fontFamily: 'JohnsonDisplay', color: 'black', lineHeight: '0', paddingBottom: 5, whiteSpace: 'nowrap', fontSize: 'calc(16px + 1vw)'
            }}
          >
            {`${t('ApplicationHeader')}`}
          </Typography>
        </div>
        <LoadingIcon isLoading={isPageLoading || userCallStatus === 'failed'}>
          <div className={styles['middle-section']}>
            <LoadingIcon isLoading={appGridIsLoading}>
              <div className={styles['app-grid']}>
                <ApplicationGrid applications={userAppsData} noAppsMsg={t('NoAppsMsg')} showLabels="true" />
              </div>
            </LoadingIcon>
          </div>
          <div className={styles['jnj-footer']}>
            <JNJFooter text={t('FooterText')} footerSize="medium" />
          </div>
        </LoadingIcon>
      </div>
    </MarginWrapper>
  )
}

DashboardDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  userObjData: PropTypes.shape({
    login: PropTypes.string,
    status: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    preferredLanguage: PropTypes.string,
    secondEmail: PropTypes.string,
    displayName: PropTypes.string,
    created: PropTypes.string.isRequired,
    lastLogin: PropTypes.string.isRequired,
  }).isRequired,
  userAppsData: PropTypes.arrayOf(PropTypes.shape({
    // remember to define shape, placeholder
    appId: PropTypes.string.isRequired,
    appName: PropTypes.string.isRequired,
  })).isRequired,
  appGridIsLoading: PropTypes.bool.isRequired,
}

export default DashboardDesktop
