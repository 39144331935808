// ErrorPage.js
import React, { useEffect } from 'react'
import WarningAmber from '@mui/icons-material/WarningAmber'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { setOverlayError, selectErrorObject, selectOAuthError } from './errorSlice'
import styles from './ErrorPage.module.scss'
import errorBanner from '../../assets/error_banner.png'

export function ErrorPage() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const errorObject = useSelector(selectErrorObject)
  const oauthErrorMessage = useSelector(selectOAuthError)
  const defaultMessage = t('error-defaultMessage')

  const errorCode = errorObject.code || errorObject.overlayCode || 'N/A'
  let errorMessage

  // Determine which message to display
  if (typeof errorCode === 'number' && errorCode < 500) {
    // Custom error message shown only if error code is below 500
    errorMessage = errorObject.message || errorObject.overlayMessage
  } else {
    errorMessage = oauthErrorMessage || defaultMessage
  }

  const errorSubText = errorMessage === defaultMessage ? t('error-defaultSubText') : ''

  useEffect(() => {
    // Hide the side menu if present
    const sideMenuElement = document.getElementById('side-menu')
    if (sideMenuElement) {
      sideMenuElement.style.display = 'none'
    }
  }, [])

  // clear error state so that App.js does not automatically re-navigate user to /error
  const clearError = () => {
    dispatch(setOverlayError({ message: '' }))
    // Clear the logout query parameter
    window.history.replaceState({}, document.title, window.location.pathname)
    // Clear the logoutWarning flag from session storage
    sessionStorage.removeItem('logoutWarning')
  }

  return (
    <div className={styles['error-page']}>
      <img className={styles['error-banner']} src={errorBanner} alt="error logo" />
      <div className={styles['error-notification']}>
        <div className={styles['error-icon']}>
          <WarningAmber sx={{ fontSize: 55, stroke: 'white', strokeWidth: 0.7 }} />
        </div>
        <div className={styles['error-message']}>
          <div className={styles['error-code-details']}>
            <span className={(errorCode === 'N/A' && styles.hidden)}>
              Error code: {errorCode}
            </span>
          </div>
          <div className={styles['error-maintext']}>{errorMessage}</div>
          <div className={styles['error-subtext']}>{errorSubText}</div>

          <span className={styles['sign-in-again']}>
            <Link to="/login" onClick={clearError}>{t('sign-in-again-prompt')}</Link>
          </span>
        </div>
      </div>
    </div>
  )
}
