import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Paper } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { useLocation } from 'react-router-dom'
import styles from './Login.module.scss'
import isMobileView from '../../util/MobileView'
import { Button } from '../section-components/Button'
import { GATEWAY_API_LOGIN } from '../../util/config'
import Alert from '../error/Alert'

export default function Login() {
  const { t } = useTranslation()
  const isMobile = isMobileView()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const loggedOut = queryParams.get('logout')
  const [logoutWarning, setLogoutWarning] = useState(sessionStorage.getItem('logoutWarning'))

  if (localStorage.loggedIn) {
    // user was logged in but was signed out for expired cookie, preserve logoutWarning
    localStorage.removeItem('loggedIn')
  } else {
    sessionStorage.removeItem('logoutWarning')
  }

  useEffect(() => {
    if (loggedOut) {
      sessionStorage.removeItem('logoutWarning')
      setLogoutWarning(null)
    }
  }, [loggedOut])

  const renderAlert = (headerText, message, alertStyle) => (
    <div style={{ paddingBottom: '24px' }}>
      <Alert
        headerText={headerText}
        message={message}
        alertStyle={alertStyle}
        showClose={false}
      />
    </div>
  )

  const renderLoginContent = () => (
    <>
      {logoutWarning && renderAlert(
        t('unauthorized-access-header'),
        t('unauthorized-access-message'),
        'warning-alert'
      )}
      {loggedOut === 'true' && renderAlert(
        '',
        t('signed-out-message'),
        'success-one-line'
      )}
      <div className={isMobile ? styles['mobile-login-title'] : styles['login-title']}>
        {loggedOut === 'true' ? t('welcome-signed-out') : t('welcome-message')}
      </div>
      <div className={isMobile ? styles['mobile-login-sub-title'] : styles['login-sub-title']}>
        {t('sign-in-blurb')}
      </div>
      <div className={isMobile ? styles['login-button-mobile'] : styles['login-button']}>
        <Button
          text={t('LogIn')}
          buttonStyle="cta-red-oval"
          onClick={() => {
            window.location.href = GATEWAY_API_LOGIN

            return false
          }}
        />
      </div>
      <div className={isMobile ? styles['mobile-login-footer-blurb'] : styles['login-footer-blurb']}>
        {t('sign-in-footer-blurb')}
      </div>
    </>
  )

  if (isMobile) {
    return (
      <div>
        <AppBar className="mobile-login-app-bar" position="relative" sx={{ backgroundColor: '#EB1700', height: '100px' }}>
          <Typography className={styles['mobile-login-app-bar-header']} sx={{ fontFamily: 'JohnsonDisplay', fontSize: '30px', fontWeight: 'bolder' }}>
            {t('sign-in-header')}
          </Typography>
        </AppBar>
        <Container maxWidth={false} className={styles['mobile-container']}>
          <div className={styles['mobile-login-content']} />
          <Paper elevation={0} className={styles['mobile-login-content']}>
            {renderLoginContent()}
          </Paper>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <AppBar className={styles['login-app-bar']} sx={{ backgroundColor: '#EB1700', height: '140px' }}>
        <Typography className={styles['login-app-bar-header']} sx={{ fontFamily: 'JohnsonDisplay', fontSize: '30px', fontWeight: 'bolder' }}>
          {t('sign-in-header')}
        </Typography>
      </AppBar>
      <div className={styles['full-viewport-height']}>
        <Paper elevation={0} className={styles['login-content']}>
          {renderLoginContent()}
        </Paper>
      </div>
    </div>
  )
}
