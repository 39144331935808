import React from 'react'
import PropTypes from 'prop-types'
import FormDesktop from './FormDesktop'
import FormMobile from './FormMobile'
import isMobileView from '../../../util/MobileView'

function Form({
  t,
  userFields,
  validFields,
  validateChange,
  handleBlur,
  handleSubmit,
  isFormValid,
  TextMaskCustom,
  onCancel,
  onError,
  setUserFields,
}) {
  const isMobile = isMobileView()

  return isMobile ? (
    <FormMobile
      t={t}
      userFields={userFields}
      validFields={validFields}
      validateChange={validateChange}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      isFormValid={isFormValid}
      TextMaskCustom={TextMaskCustom}
      onCancel={onCancel}
      onError={onError}
      setUserFields={setUserFields}
    />
  ) : (
    <FormDesktop
      t={t}
      userFields={userFields}
      validFields={validFields}
      validateChange={validateChange}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      isFormValid={isFormValid}
      TextMaskCustom={TextMaskCustom}
      onCancel={onCancel}
      onError={onError}
      setUserFields={setUserFields}
    />
  )
}

Form.propTypes = {
  t: PropTypes.func.isRequired,
  userFields: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    secondEmail: PropTypes.string,
    mobilePhone: PropTypes.string,
  }).isRequired,
  validFields: PropTypes.shape({
    firstName: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    lastName: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    email: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    secondEmail: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    mobilePhone: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
  }).isRequired,
  validateChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  TextMaskCustom: PropTypes.elementType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setUserFields: PropTypes.func.isRequired,
}

export default Form
