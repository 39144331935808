import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { uploadProfilePicture } from '../user/profilePictureSlice'
import { validateImageSize } from '../../util/image/validateImageSize'

export function useFileSelectedHandler(setFile, setError, fileInputRef) {
  return async (acceptedFiles) => {
    if (acceptedFiles[0]) {
      const image = acceptedFiles[0]
      try {
        await validateImageSize(image)
        setFile(image)
        setError('')
      } catch (err) {
        setError(err.message)
        setFile(null)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
      }
    }
  }
}

export function useSaveProfilePictureHandler(file, onFileUpload, setDialogOpen, setError) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return async () => {
    if (file) {
      try {
        const response = await dispatch(uploadProfilePicture(file)).unwrap()
        onFileUpload(response)
        setDialogOpen(false)
      } catch (uploadError) {
        setError(t('pfp-failed-upload-text'))
      }
    }
  }
}

export function useCancelHandler(setFile, setError, setDialogOpen) {
  return () => {
    setFile(null)
    setError('')
    setDialogOpen(false)
  }
}
