import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_PROFILE_PICTURE_URL } from '../../util/config'
import { fileToBase64 } from '../../util/image/getBase64'

const initialState = {
  status: 'idle',
  image: null,
  error: null
}

export const fetchProfilePicture = createAsyncThunk(
  'profilePicture/fetchProfilePicture',
  async (_, { rejectWithValue }) => {
    try {
      const response = await gatewayRequestAxios.get(GATEWAY_API_PROFILE_PICTURE_URL)

      if (response.status === 200) {
        return response.data
      }
      return ''
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const uploadProfilePicture = createAsyncThunk('profilePicture/uploadProfilePicture', async (file, { rejectWithValue }) => {
  try {
    const base64String = await fileToBase64(file)
    const reqJson = { base64: base64String }
    const response = await gatewayRequestAxios.post(GATEWAY_API_PROFILE_PICTURE_URL, reqJson)
    if (response.status !== 201) {
      throw new Error('Failed to upload profile picture')
    }

    const reqBase64Image = `data:${file.type};base64,${reqJson.base64}` // new prof pic in readable base64
    return reqBase64Image
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const deleteProfilePicture = createAsyncThunk(
  'profilePicture/deleteProfilePicture',
  async (_, { rejectWithValue }) => {
    try {
      const response = await gatewayRequestAxios.delete(`${GATEWAY_API_PROFILE_PICTURE_URL}`)

      if (response.status !== 204) {
        throw new Error('Failed to delete profile picture')
      }

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const profilePictureSlice = createSlice({
  name: 'profilePicture',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProfilePicture.pending, (state) => {
        state.status = 'loading'
        state.image = ''
        state.error = null
      })
      .addCase(fetchProfilePicture.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.image = action.payload.image
        state.error = null
      })
      .addCase(fetchProfilePicture.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
        state.image = ''
      })
      .addCase(uploadProfilePicture.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.image = action.payload
        state.error = null
      })
      .addCase(uploadProfilePicture.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(deleteProfilePicture.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteProfilePicture.fulfilled, (state) => {
        state.status = 'succeeded'
        state.image = ''
        state.error = null
      })
      .addCase(deleteProfilePicture.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})

export default profilePictureSlice.reducer

export const selectProfilePictureStatus = (state) => state.profilePicture.status
export const selectProfilePictureImage = (state) => state.profilePicture.image
export const selectProfilePictureError = (state) => state.profilePicture.error
