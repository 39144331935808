import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enUSTranslations from '../features/locales/en-US.json'
import esESTranslations from '../features/locales/es-ES.json'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: 'en-US',
    resources: {
      'en-US': {
        translation: enUSTranslations,
      },
      'es-ES': {
        translation: esESTranslations,
      },
    },
  })

export default i18next
