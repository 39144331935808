import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../section-components/Button'
import styles from './EditAccount.module.scss'
import { LoadingIcon } from '../section-components/LoadingIcon'
import MarginWrapper from '../wrappers/MarginWrapper'
import { UserProfile } from '../user/UserProfile'
import { SectionHeader } from '../section-components/SectionHeader'

function EditAccountDesktop({
  t,
  userFields,
  userProfilePictureData,
  toggleEditAccount,
  showEditAccount,
  editAccountComponent,
}) {
  return (
    <MarginWrapper>
      <LoadingIcon isLoading={false}>
        <div className={styles['parent-container']}>
          <div className={styles['my-account-container']}>
            <div className={styles['account-header']}>
              <SectionHeader text={t('AccountInfoHeader')} size="large" />
            </div>
            <div className={styles['profile-info-container']}>
              <div className={styles['profile-picture-container']}>
                <div className={styles['profile-picture-wrapper']}>
                  <UserProfile
                    isInteractive={false}
                    profilePictureData={userProfilePictureData}
                    initials={`${userFields.firstName[0]}${userFields.lastName[0]}`}
                  />
                </div>
                <div className={styles['profile-picture-support-text']}>
                  {t('support-text-profile-pic')}
                </div>
              </div>
              <SectionHeader text={t('UserAccountInfoHeader')} size="medium" />
              {!showEditAccount && (
                <div className={styles.buttons}>
                  <Button
                    text={t('button-edit-profile')}
                    buttonStyle="cta-grey-oval"
                    onClick={toggleEditAccount}
                  />
                </div>
              )}
              {showEditAccount && editAccountComponent}
            </div>
          </div>
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

EditAccountDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  userFields: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    secondEmail: PropTypes.string,
    mobilePhone: PropTypes.string,
  }).isRequired,
  validFields: PropTypes.shape({
    firstName: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    lastName: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    email: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    secondEmail: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
    mobilePhone: PropTypes.shape({
      isValid: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
  }).isRequired,
  userProfilePictureData: PropTypes.string,
  toggleEditAccount: PropTypes.func.isRequired,
  showEditAccount: PropTypes.bool.isRequired,
  editAccountComponent: PropTypes.element
}

EditAccountDesktop.defaultProps = {
  userProfilePictureData: '',
  editAccountComponent: null,
}

export default EditAccountDesktop
