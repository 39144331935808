import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Grid from '@mui/system/Unstable_Grid/Grid'
import { useSelector } from 'react-redux'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { UserProfile } from '../../features/user/UserProfile'
import { Button } from '../../features/section-components/Button'
import styles from './MyAccount.module.scss'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import '../../util/i18n'
import Alert from '../../features/error/Alert'
import { selectUserCallError, selectUserCallStatus } from '../../features/user/userSlice'
import { PictureUpload } from '../../features/PictureUpload'

function MyAccountMobile({
  t, userObj, userProfilePictureData, fullName, isPageLoading, renderData,
  formatPhoneToNational, preferredLanguage, languageSelectorComponent, toggleLanguageSelector,
  showLanguageSelector, languageOptions, setAlertVisible, editAccountComponent,
  toggleEditAccount, showEditAccount, showProfileInfo, handlePhotoDelete
}) {
  const userError = useSelector(selectUserCallError)
  const userCallStatus = useSelector(selectUserCallStatus)
  const initials = `${userObj && userObj.firstName ? userObj.firstName[0] : ''}${userObj && userObj.lastName ? userObj.lastName[0] : ''}`

  return (
    <MarginWrapper>
      <LoadingIcon isLoading={isPageLoading || userCallStatus === 'failed'}>
        <div className={styles['mobile-my-account-container']}>
          <div className={styles['mobile-section-header-left-align']} style={{ marginTop: '75px' }}>
            {t('AccountInfoHeader')}
          </div>
          {userError && (
            <Alert
              message={t('error-saveFailed')}
              onClose={() => setAlertVisible(false)}
              alertStyle="error-alert"
              headerText={t('error')}
            />
          )}
          <div className={styles['mobile-profile-info-container']}>
            <div className={styles['mobile-profile-picture-container']}>
              <div className={styles['mobile-profile-picture-wrapper']}>
                <UserProfile
                  isInteractive={false}
                  profilePictureData={userProfilePictureData}
                  initials={initials}
                />
              </div>
              <div className={styles['mobile-profile-picture-support-text']}>
                <div className={styles['mobile-upload-button']}>
                  <PictureUpload />
                </div>
                <div className={styles['mobile-delete-button']}>
                  {t('support-text-profile-pic')}
                  {userProfilePictureData ? (
                    <Button
                      text="Delete photo"
                      buttonStyle="cta-yellow-oval-mobile"
                      disabled={false}
                      icon={<DeleteOutlineIcon />}
                      iconSize="small"
                      onClick={handlePhotoDelete}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles['mobile-user-profile-info-container']}>
              <div className={styles['mobile-section-header-left-align']}>
                {t('UserAccountInfoHeader')}
              </div>
              {showProfileInfo && (
              <>
                {renderData(t('label-name'), fullName)}
                {renderData(t('label-email'), userObj.email)}
                {(userObj.secondEmail) && renderData(t('label-second-email'), userObj.secondEmail)}
                {(userObj.mobilePhone) && renderData(t('label-phone-num'), formatPhoneToNational(userObj.mobilePhone))}
              </>
              )}
              {!showEditAccount && (
              <div className={styles.buttons}>
                <Grid className={styles['mobile-manage-button']}>
                  <Button
                    text={t('button-edit-profile')}
                    buttonStyle="cta-grey-oval"
                    onClick={toggleEditAccount}
                  />
                </Grid>
              </div>
              )}
              {showEditAccount && editAccountComponent}
            </div>
          </div>
          <div className={styles['mobile-pref-lang-container']}>
            <div className={styles['mobile-section-header-left-align']}>
              {t('label-header-pref-lang')}
            </div>
            {t('support-text-pref-lang')}
            {/* Render language information only when showLanguageSelector is false */}
            {!showLanguageSelector && (
              renderData(t('label-pref-lang'), languageOptions[preferredLanguage])
            )}
            <div className={styles['mobile-edit-language-button']}>
              <Grid>
                {/* Render the Edit button only when showLanguageSelector is false */}
                {!showLanguageSelector && (
                  <Button
                    text={t('label-edit-language')}
                    buttonStyle="cta-grey-oval"
                    onClick={toggleLanguageSelector}
                  />
                )}
                {/* Always render languageSelectorComponent when showLanguageSelector is true */}
                {showLanguageSelector && languageSelectorComponent}
              </Grid>
            </div>
          </div>

          <div className={styles['mobile-password-container']} style={{ lineHeight: '1.2', marginTop: '40px' }}>
            <div className={styles['mobile-section-header-left-align']}>
              {t('label-header-password')}
            </div>
            {t('support-text-pass', { email: userObj.email })}
            <div className={styles.buttons}>
              <Grid className={styles['mobile-manage-button']}>
                <Link to="/reset-password" style={{ textDecoration: 'none' }}>
                  <Button text={t('button-reset-password')} buttonStyle="cta-grey-oval" />
                </Link>
              </Grid>
            </div>
          </div>
          <JNJFooter> </JNJFooter>
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

MyAccountMobile.propTypes = {
  t: PropTypes.func.isRequired,
  userObj: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    secondEmail: PropTypes.string,
    mobilePhone: PropTypes.string,
    created: PropTypes.string,
    preferredLanguage: PropTypes.string
  }).isRequired,
  userProfilePictureData: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  renderData: PropTypes.func.isRequired,
  formatPhoneToNational: PropTypes.func.isRequired,
  preferredLanguage: PropTypes.string.isRequired,
  languageSelectorComponent: PropTypes.bool.isRequired,
  toggleLanguageSelector: PropTypes.func.isRequired,
  showLanguageSelector: PropTypes.bool.isRequired,
  languageOptions: PropTypes.objectOf(PropTypes.string).isRequired,
  setAlertVisible: PropTypes.bool.isRequired,
  editAccountComponent: PropTypes.element,
  toggleEditAccount: PropTypes.func.isRequired,
  showEditAccount: PropTypes.bool.isRequired,
  showProfileInfo: PropTypes.bool.isRequired,
  handlePhotoDelete: PropTypes.func.isRequired
}

MyAccountMobile.defaultProps = {
  userProfilePictureData: '',
  editAccountComponent: null
}

export default MyAccountMobile
