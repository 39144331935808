import React from 'react'
import { useTranslation } from 'react-i18next'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import SupportMobile from './SupportMobile'
import SupportDesktop from './SupportDesktop'

export function Support() {
  const { t } = useTranslation()

  return (
    <MarginWrapper>
      {isMobileView ? (
        <SupportMobile
          t={t}
        />
      ) : (
        <SupportDesktop
          t={t}
        />
      )}
    </MarginWrapper>
  )
}

export default Support
