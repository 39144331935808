import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchHealthCheck, getHealthCheckError, getHealthCheckStatus, selectHealthCheck
} from '../../features/health/healthCheckSlice'
import {
  fetchUser, selectUserCallStatus,
} from '../../features/user/userSlice'
import {
  selectProfilePictureStatus, fetchProfilePicture,
} from '../../features/user/profilePictureSlice'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import styles from './Health.module.scss'
import isMobileView from '../../util/MobileView'
import HealthDesktop from './HealthDesktop'
import HealthMobile from './HealthMobile'
import MarginWrapper from '../../features/wrappers/MarginWrapper'

function ServerStatusIndicator({ status, healthCheck, t }) {
  if (status === 'loading' || status === 'idle') {
    return (
      <div className={styles.serverStatus}>
        <span className={`${styles.statusDot} ${styles.gray}`} />
        <span className={styles.statusText}>{t('health-loading-status')}</span>
      </div>
    )
  }

  let statusColor = 'gray'
  let statusText = t('health-status-false')

  if (healthCheck.idp === true) {
    statusColor = 'green'
    statusText = t('health-status-true')
  } else if (healthCheck.idp === false) {
    statusColor = 'red'
    statusText = t('health-status-issues')
  }

  return (
    <div className={styles.serverStatus}>
      <span className={`${styles.statusDot} ${styles[statusColor]}`} />
      <span className={styles.statusText}>{statusText}</span>
    </div>
  )
}

ServerStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  healthCheck: PropTypes.shape({
    idp: PropTypes.bool,
  }).isRequired,
  t: PropTypes.func.isRequired
}

export function Health() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const healthCheck = useSelector(selectHealthCheck)
  const status = useSelector(getHealthCheckStatus)
  const error = useSelector(getHealthCheckError)
  const userCallStatus = useSelector(selectUserCallStatus)
  const userProfilePictureStatus = useSelector(selectProfilePictureStatus)
  const [refresh, setRefresh] = useState(false)
  const isMobile = isMobileView()

  const handleRefresh = () => {
    if (!refresh) {
      setRefresh(true)
      dispatch(fetchHealthCheck())
        .then(() => setRefresh(false))
        .catch(() => setRefresh(false))
    }
  }

  useEffect(() => {
    if (userCallStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userCallStatus, dispatch])

  useEffect(() => {
    if (userProfilePictureStatus === 'idle') {
      dispatch(fetchProfilePicture())
    }
  }, [userProfilePictureStatus, dispatch])

  useEffect(() => {
    if (!refresh) {
      dispatch(fetchHealthCheck())
    }
  }, [dispatch, refresh])

  let content
  if (status === 'loading' || status === 'idle') {
    content = (
      <div className={styles.loadingContainer} style={{ marginBottom: '150px', display: 'flex', alignItems: 'center' }}>
        <LoadingIcon isLoading />
      </div>
    )
  } else if (status === 'failed') {
    content = <p>Error: {error}</p>
  } else {
    content = (
      <table className={styles.healthTable}>
        <tbody>
          <tr className={styles.alternateRow}>
            <th className={styles.headerCell}>{t('health-status-title')}</th>
            <td className={styles.cell}>{healthCheck.status ? t('health-status-healthy') : t('health-status-unhealthy')}</td>
          </tr>
          <tr className={styles.row}>
            <th className={styles.headerCell}>{t('health-uptime-title')}</th>
            <td className={styles.cell}>{healthCheck.uptime}</td>
          </tr>
          <tr className={styles.alternateRow}>
            <th className={styles.headerCell}>{t('health-idp-title')}</th>
            <td className={styles.cell}>{healthCheck.idp ? t('true') : t('false')}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <MarginWrapper>
      {isMobile ? (
        <HealthMobile
          t={t}
          status={status}
          healthCheck={healthCheck}
          error={error}
          refresh={refresh}
          handleRefresh={handleRefresh}
          ServerStatusIndicator={ServerStatusIndicator}
          content={content}
        />
      ) : (
        <HealthDesktop
          t={t}
          status={status}
          healthCheck={healthCheck}
          error={error}
          refresh={refresh}
          handleRefresh={handleRefresh}
          ServerStatusIndicator={ServerStatusIndicator}
          content={content}
        />
      )}
    </MarginWrapper>
  )
}

export default Health
