import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './LoadingIcon.module.scss'

/* Scenario: Not loading any data
Given this component is rendered to the page
And this component has a provided child element contained within it
When the property `is-loading`1 is set to false or is not provided
Then the child elements are displayed normally
And there is no indication that the page is loading

Scenario: Is Loading Data
Given this component is rendered to the page
And this component has a provided child element contained within it
When the property `is-loading`1 is set to true
Then the child elements are not visible within the component
And a loading icon^2^ is displayed to the user */

export function LoadingIcon(props) {
  const isLoadingStr = props.isLoading ? 'loading' : 'loaded'
  const content = props.isLoading ? <CircularProgress /> : props.children

  return (
    <div className={styles[`LoadingIcon-container-${isLoadingStr}`]}>
      { content }
    </div>
  )
}

LoadingIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isLoading: PropTypes.bool,
}

LoadingIcon.defaultProps = {
  children: null,
  isLoading: false,
}
