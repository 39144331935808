import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { Button } from '../section-components/Button'
import { ImageDisplayBox } from '../section-components/ImageDisplayBox'
import { FileUploadDesktop } from '../section-components/FileUpload/FileUploadDesktop'
import Alert from '../error/Alert'
import styles from './PictureUpload.module.scss'
import { useFileSelectedHandler, useSaveProfilePictureHandler, useCancelHandler } from './PictureUploadManager'

export function PictureUploadDesktop({ onFileUpload }) {
  const { t } = useTranslation()
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const fileInputRef = useRef(null)

  const handleFileSelected = useFileSelectedHandler(setFile, setError, fileInputRef)
  const handleSaveProfilePicture = useSaveProfilePictureHandler(
    file, onFileUpload, setDialogOpen, setError)
  const handleCancel = useCancelHandler(setFile, setError, setDialogOpen)

  const fileUploadClass = error
    ? `${styles.fileUploadBoxError}`
    : styles.fileUploadBox

  return (
    <div>
      <Button
        text={t('pfp-upload-button-text')}
        buttonStyle="cta-red-oval"
        variant="contained"
        onClick={() => setDialogOpen(true)}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        PaperProps={{
          className: styles.dialogContainer,
        }}
      >
        <div className={styles.closeButton}>
          <IconButton onClick={handleCancel} aria-label="close">
            <CloseIcon />
          </IconButton>
          <p className={styles.closeText}>{t('pfp-close-text')}</p>
        </div>
        <DialogContent className={styles.dialogContent}>
          <p className={styles.uploadText}>
            {t('pfp-upload-upload-photo-text')}
          </p>
          {error && (
            <div className={styles.alertContainer}>
              <Alert
                headerText={t('incompatible-file')}
                message={t('pfp-try-again-error')}
                alertStyle="error-alert"
                onClose={() => setError('')}
                showClose={false}
                blurb={(
                  <p>
                    {t('pfp-image-size-error')} <br />
                    {t('pfp-file-type-error')} <br />
                    {t('pfp-size-limit-error')}
                  </p>
                      )}
              />
            </div>
          )}
          {!file && (
            <div className={fileUploadClass}>
              <FileUploadDesktop
                key={error ? 'reset-upload' : 'upload'}
                ref={fileInputRef}
                image
                acceptedFileTypes="image/*"
                onFile={handleFileSelected}
                text1={t('drag-and-drop-text')}
                linkText={t('browse-text')}
                text2={t('to-upload-text')}
              />
            </div>
          )}
          {file && !error && (
            <div className={styles.imageDisplayContainer}>
              <ImageDisplayBox
                image={URL.createObjectURL(file)}
                square="300px"
              />
            </div>
          )}
          <DialogActions
            className={`${styles.dialogActions} ${file && !error && styles.dialogActionsLeft}`}
          >
            <div className={styles.buttonContainer}>
              <div className={styles.saveButton}>
                <Button
                  text={t('pfp-save-text')}
                  buttonStyle="cta-red-oval"
                  onClick={handleSaveProfilePicture}
                  disabled={!file || error}
                />
              </div>
              <Button
                text={t('button-cancel')}
                buttonStyle="cta-grey-oval"
                onClick={handleCancel}
              />
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

PictureUploadDesktop.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
}
