import React from 'react'
import PropTypes from 'prop-types'
import MarginWrapper from '../../features/wrappers/MarginWrapper'

function SupportDesktop({ t }) {
  return (
    <MarginWrapper>
      <div>
        {t('support-page-text-pre')}
        <a href={`mailto:${t('support-email-link')}`}>{t('support-email-link')}</a>
        {t('support-page-text-post')}
      </div>
    </MarginWrapper>
  )
}

SupportDesktop.propTypes = {
  t: PropTypes.func.isRequired
}

export default SupportDesktop
