const mobileKeywords = /Mobi|Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i

const isTabletDevice = () => !mobileKeywords.test(navigator.userAgent) && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0

const isMobileView = () => {
  const isTablet = isTabletDevice()
  return mobileKeywords.test(navigator.userAgent) || isTablet
}

export default isMobileView
