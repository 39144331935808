import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import { InfoOutlined, WarningAmber } from '@mui/icons-material'
import icon from '../../assets/Checkmark.svg'

function AlertStyles({ alertStyle, headerText }) {
  const styles = {
    height: '40px',
    width: '350px',
    padding: '8px',
    gap: '8px',
  }

  let backgroundColor; let iconComponent; let style; let header; let messageStyle; let
    close

  switch (alertStyle) {
    case 'error-alert':
      backgroundColor = '#FDE3D7'
      iconComponent = <ReportGmailerrorredIcon sx={{ color: '#AA1D1B', mt: -2.5 }} />
      header = headerText || 'Error message'
      messageStyle = { textDecorationLine: 'underline', color: '#4C433E' }
      close = <CloseIcon fontSize="small" sx={{ mt: -2.5 }} />
      break
    case 'info-alert':
      backgroundColor = '#E1F6FF'
      iconComponent = <InfoOutlined sx={{ color: '#0F68B2', mt: -2.5 }} />
      header = headerText || 'Alert message'
      close = <CloseIcon fontSize="small" sx={{ mt: -2.5 }} />
      break
    case 'success-alert':
      backgroundColor = '#DDF5E4'
      iconComponent = (
        <img
          src={icon}
          alt=""
          style={{
            color: '#2C7C14', marginTop: '-16px', height: '26px', width: '25px'
          }}
        />
      )
      header = headerText || "Success - you've been logged out."
      messageStyle = { textDecorationLine: 'underline', color: '#4C433E' }
      close = <CloseIcon fontSize="small" sx={{ mt: -2.5 }} />
      break
    case 'warning-alert':
      backgroundColor = '#FFF5C5'
      iconComponent = <WarningAmber sx={{ color: '#FF621A', mt: -2.5 }} />
      header = headerText || 'Error message'
      messageStyle = { textDecorationLine: 'underline', color: '#4C433E' }
      close = <CloseIcon fontSize="small" sx={{ mt: -2.5 }} />
      break
    case 'short-error-alert':
      backgroundColor = '#FDE3D7'
      iconComponent = <ReportGmailerrorredIcon sx={{ color: '#AA1D1B', mt: 0 }} />
      style = styles
      close = <CloseIcon fontSize="small" sx={{ mt: 0 }} />
      break
    case 'short-info-alert':
      backgroundColor = '#E1F6FF'
      iconComponent = <InfoOutlined sx={{ color: '#0F68B2', mt: 0 }} />
      style = styles
      close = <CloseIcon fontSize="small" sx={{ mt: 0 }} />
      break
    case 'short-success-alert':
      backgroundColor = '#DDF5E4'
      iconComponent = (
        <img
          src={icon}
          alt=""
          style={{
            color: '#2C7C14', mt: -2.5, height: '26px', width: '25px'
          }}
        />
      )
      style = styles
      close = <CloseIcon fontSize="small" sx={{ mt: 0 }} />
      break
    case 'short-warning-alert':
      backgroundColor = '#FFF5C5'
      iconComponent = <WarningAmber sx={{ color: '#FF621A', mt: 0 }} />
      style = styles
      close = <CloseIcon fontSize="small" sx={{ mt: 0 }} />
      break
    case 'success-one-line':
      backgroundColor = '#DDF5E4'
      iconComponent = (
        <img
          src={icon}
          alt=""
          style={{
            color: '#2C7C14', mt: -2.5, height: '26px', width: '25px'
          }}
        />
      )
      close = <CloseIcon fontSize="small" sx={{ mt: 0 }} />
      break
    default:
      backgroundColor = '#FFFFFF'
      iconComponent = null
  }
  return {
    backgroundColor,
    iconComponent,
    style,
    header,
    messageStyle,
    close
  }
}

export default AlertStyles
