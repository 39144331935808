import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import styles from './ResetPassword.module.scss'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import '../../util/i18n'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import { MobileResetPasswordPage, MobileResetPasswordConfirm } from './ResetPasswordManager'

function ResetPasswordMobile({
  t, userCallStatus, sendResetPasswordEmail, pwButtonisLoading, handleSubmit, isLoading,
  renderData, userObj
}) {
  return (
    <MarginWrapper>
      <LoadingIcon isLoading={pwButtonisLoading || userCallStatus === 'loading' || userCallStatus === 'idle' || userCallStatus === 'failed'}>
        <Grid container className={styles['mobile-reset-password-container']}>
          <Grid item xs={12} md={12} lg={12} xl={12} className={styles['mobile-reset-password-header']}>
            {isLoading && <LoadingIcon />}
            <div className={styles['mobile-home-link-container']}>
              <Link to="/home" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', }} color="black">
                <ChevronLeftOutlinedIcon className={styles['mobile-home-icon']} sx={{ width: '.6em' }} />
                <div className={styles['mobile-home-text']}>
                  {t('home-tab')}
                </div>
              </Link>
            </div>
            <SectionHeader text={t('button-reset-password')} size="large" />
          </Grid>
          {(!sendResetPasswordEmail)
            ? (
              MobileResetPasswordPage(t, handleSubmit, renderData, userObj)
            )
            : (
              MobileResetPasswordConfirm(t, handleSubmit)
            )}
        </Grid>
        <div className={styles['JNJ-footer']}>
          <JNJFooter />
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

ResetPasswordMobile.propTypes = {
  t: PropTypes.func.isRequired,
  userCallStatus: PropTypes.string.isRequired,
  sendResetPasswordEmail: PropTypes.bool.isRequired,
  pwButtonisLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userObj: PropTypes.shape({
    email: PropTypes.string
  }),
  renderData: PropTypes.func.isRequired
}

ResetPasswordMobile.defaultProps = {
  userObj: ''
}

export default ResetPasswordMobile
