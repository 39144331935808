export const GATEWAY_API_BASEPATH = process.env.REACT_APP_GATEWAY_API_BASEPATH
export const GATEWAY_API_HEALTH_URL = `${GATEWAY_API_BASEPATH}/api/health`
export const GATEWAY_API_USER_URL = `${GATEWAY_API_BASEPATH}/api/me`
export const GATEWAY_API_ERROR_URL = `${GATEWAY_API_BASEPATH}/error`
export const GATEWAY_API_PROFILE_PICTURE_URL = `${GATEWAY_API_BASEPATH}/api/me/profile-picture`
export const GATEWAY_API_USER_REGISTERED_APPS_URL = `${GATEWAY_API_BASEPATH}/api/me/applications`
export const GATEWAY_API_RESET_PASSWORD = `${GATEWAY_API_BASEPATH}/api/me/reset-password`
export const GATEWAY_API_LOGIN = `${GATEWAY_API_BASEPATH}/api/auth/login`
export const GATEWAY_API_LOGOUT = `${GATEWAY_API_BASEPATH}/api/auth/logout`
export const API_AUTH_COOKIE_NAME = 'connect.sid'
export const EXCEPTION_ROUTES = ['/health']
export const BANNER_MENU_SECTIONS = [
  {
    label: 'home-tab',
    path: '/'
  },
  {
    label: 'support-tab',
    path: '/support'
  }
]
export const BANNER_DROPDOWN_MENU_SECTIONS = [
  {
    label: 'account-tab',
    path: '/account',
  },
  {
    label: 'button-reset-password',
    path: '/reset-password',
  },
  {
    label: 'sign-out-tab',
    path: '/logout',
    color: 'red'
  }
]
export const HAMBURGER_MENU_SECTIONS = [
  [
    {
      label: 'home-tab',
      path: '/'
    },
    {
      label: 'account-tab',
      path: '/account'
    },
    {
      label: 'button-reset-password',
      path: '/reset-password'
    },
    {
      label: 'support-tab',
      path: '/support'
    }
  ],
  [
    {
      label: 'sign-out-tab',
      path: '/logout'
    },
  ]
]
export const SIDE_MENU_SECTIONS = [
  {
    name: 'SideMenuHeaderOne',
    links: [
      { label: 'dashboard-tab', path: '/' }
    ]
  },
  {
    name: 'SideMenuHeaderTwo',
    links: [
      { label: 'my-account-tab', path: '/account' },
    ]
  },
  {
    name: null,
    links: [
      { label: 'log-out-tab', path: '/logout', fontColor: '#EB1700' }
    ]
  },
]
