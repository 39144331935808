import React from 'react'

export const renderImage = (imageUrl, name, styles) => {
  if (imageUrl) {
    return <img src={imageUrl} alt={`${name} logo`} className={styles.appLogo} />
  }

  if (typeof name === 'string' && name.length > 0) {
    return <div className={styles.appInitial}>{name.charAt(0)}</div>
  }

  return <div className={styles.appInitial}>?</div>
}
