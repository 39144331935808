/* eslint-disable-next-line react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './FileUpload.module.scss'
import icon from '../../../assets/Icon.png'
import { useFileUploadManager } from './FileUploadManager'

export function FileUploadDesktop({
  image, acceptedFileTypes, onFile, text1, linkText, text2, resetUploadedFiles
}) {
  const {
    uploadedFiles,
    handleFileDrop,
    rootProps,
    inputRef,
    identifier
  } = useFileUploadManager(acceptedFileTypes, onFile, resetUploadedFiles)

  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div
          /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rootProps}
        className={styles['drag-drop-box']}
        onClick={() => {
          document.getElementById(identifier).click()
        }}
        onDragEnter={rootProps.onDragEnter}
        onDragLeave={rootProps.onDragLeave}
        onDragOver={rootProps.onDragOver}
        onDrop={rootProps.onDrop}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            rootProps.onClick(event)
          }
        }}
        role="button"
        tabIndex={0}
      >
        <input
          id={identifier}
          className={styles.input}
          type="file"
          accept={acceptedFileTypes}
          onChange={(event) => handleFileDrop(event.target.files, [])}
          ref={inputRef}
        />
        <div>
          {uploadedFiles.length === 0 ? (
            <div>
              {image && (
              <>
                <img src={icon} alt="" className={styles.withprompt} />
                <div className={styles['drag-drop-box-text']}>
                  <p>
                    {t(text1)}
                    <br /> <span>{t(linkText)}</span> {t(text2)}
                  </p>
                </div>
              </>
              )}
              {!image && <img src={icon} alt="" className={styles.icon} />}
            </div>
          ) : (
            <ul>
              {[...uploadedFiles].map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

FileUploadDesktop.propTypes = {
  image: PropTypes.bool.isRequired,
  acceptedFileTypes: PropTypes.string.isRequired,
  onFile: PropTypes.func.isRequired,
  text1: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  resetUploadedFiles: PropTypes.bool,
}

FileUploadDesktop.defaultProps = {
  resetUploadedFiles: false,
}
