import React from 'react'
import PropTypes from 'prop-types'
import styles from './MarginWrapper.module.scss'

function MarginWrapper({ children }) {
  return (
    <div className={`${styles.wrapper} ${styles.marginZero}`}>
      {children}
    </div>
  )
}

MarginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MarginWrapper
