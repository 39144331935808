import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import isMobileView from '../../util/MobileView'
import { ApplicationGridDesktop } from './ApplicationGridDesktop'
import { ApplicationGridMobile } from './ApplicationGridMobile'

export function ApplicationGrid(props) {
  const isMobile = isMobileView()
  const { t } = useTranslation()

  return isMobile ? (
    <ApplicationGridMobile
      applications={props.applications}
      noAppsMsg={props.noAppsMsg}
      onClick={props.onClick}
      buttonLinkText={t('open-label-text')}
    />
  ) : (
    <ApplicationGridDesktop
      applications={props.applications}
      noAppsMsg={props.noAppsMsg}
      onClick={props.onClick}
      buttonLinkText={t('open-label-text')}
      favoriteLabelText={t('favorite-label-text')}
    />
  )
}

ApplicationGrid.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.shape({
    appId: PropTypes.string,
    src: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string.isRequired,
  })),
  noAppsMsg: PropTypes.string,
  onClick: PropTypes.func,
}

ApplicationGrid.defaultProps = {
  applications: [],
  noAppsMsg: 'NoAppsMsg',
  onClick: (link) => {
    window.open(link, '_blank')
  },
}
