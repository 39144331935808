import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import LaunchIcon from '@mui/icons-material/Launch'
import { Button } from '../section-components/Button'
import styles from './AppCard.module.scss'
import { renderImage } from '../../util/image/renderImage'

export function AppCardMobile({
  name,
  imageUrl,
  onOpenButtonClick,
  onManageLinkClick,
  onFavoriteClick,
  secondaryLinkText,
  buttonLinkText,
  favoriteLabelText,
  showSecondaryLink,
  showFavoriteIcon
}) {
  return (
    <div className={styles.appCardMobile}>
      <div className={styles.appLogoContainerMobile}>{renderImage(imageUrl, name, styles)}</div>
      <div className={styles.cardContentMobile}>
        {showFavoriteIcon && onFavoriteClick && (
          <div className={styles.favoriteContainerMobile}>
            <span className={styles.favoriteTextMobile}>{favoriteLabelText}</span>
            <IconButton className={styles.favoriteIconMobile} onClick={onFavoriteClick} aria-label="Favorite">
              <FavoriteBorderIcon />
            </IconButton>
          </div>
        )}
        <div className={styles.appNameMobile}>{name}</div>
        <div className={styles.actionLinksMobile}>
          {showSecondaryLink && secondaryLinkText && onManageLinkClick && (
            <button type="button" className={styles.manageLinkMobile} onClick={onManageLinkClick}>
              {secondaryLinkText}
            </button>
          )}
          {buttonLinkText && onOpenButtonClick && (
            <Button text={buttonLinkText} buttonStyle="app-card-mobile" onClick={onOpenButtonClick} icon={<LaunchIcon />} />
          )}
        </div>
      </div>
    </div>
  )
}

AppCardMobile.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onOpenButtonClick: PropTypes.func,
  onManageLinkClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  secondaryLinkText: PropTypes.string,
  buttonLinkText: PropTypes.string,
  favoriteLabelText: PropTypes.string,
  showSecondaryLink: PropTypes.bool,
  showFavoriteIcon: PropTypes.bool
}

AppCardMobile.defaultProps = {
  imageUrl: null,
  onOpenButtonClick: null,
  onManageLinkClick: null,
  onFavoriteClick: null,
  secondaryLinkText: null,
  buttonLinkText: '',
  favoriteLabelText: null,
  showSecondaryLink: false,
  showFavoriteIcon: false
}
