import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_HEALTH_URL } from '../../util/config'

const initialState = {
  healthCheck: {},
  status: 'idle',
  error: null
}

export const fetchHealthCheck = createAsyncThunk('healthCheck/fetchHealthCheck', async () => {
  const response = await gatewayRequestAxios.get(GATEWAY_API_HEALTH_URL)
  return response.data
})

export const healthCheckSlice = createSlice({
  name: 'healthCheck',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHealthCheck.pending, (state) => {
        state.status = 'loading'
        state.healthCheck = {}
        state.error = undefined
      })
      .addCase(fetchHealthCheck.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        state.healthCheck = action.payload
      })
      .addCase(fetchHealthCheck.rejected, (state, action) => {
        state.status = 'failed'
        state.healthCheck = {}
        state.error = action.error.message
      })
  }
})

export default healthCheckSlice.reducer
export const selectHealthCheck = (state) => state.healthCheck.healthCheck
export const getHealthCheckStatus = (state) => state.healthCheck.status
export const getHealthCheckError = (state) => state.healthCheck.error
