import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_USER_REGISTERED_APPS_URL } from '../../util/config'

const initialState = {
  userAppsObj: [],
  status: 'idle',
  error: null
}

export const fetchUserApps = createAsyncThunk('userApps/fetchUserApps', async () => {
  const response = await gatewayRequestAxios.get(GATEWAY_API_USER_REGISTERED_APPS_URL)
  const jsonResp = response.data
  return jsonResp
})

export const userAppsSlice = createSlice({
  name: 'userApps',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserApps.pending, (state) => {
        state.status = 'loading'
        state.userAppsObj = []
        state.error = undefined
      })
      .addCase(fetchUserApps.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.error = undefined
        state.userAppsObj = action.payload
      })
      .addCase(fetchUserApps.rejected, (state, action) => {
        state.status = 'failed'
        state.userAppsObj = []
        state.error = action.error.message
      })
  }
})

export default userAppsSlice.reducer
export const selectUserAppsObject = (state) => state.userApps.userAppsObj
export const getUserAppsCallStatus = (state) => state.userApps.status
export const getUserAppsCallError = (state) => state.userApps.error
