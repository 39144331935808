import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import styles from './ResetPassword.module.scss'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import '../../util/i18n'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import { ResetPasswordConfirm, ResetPasswordPage } from './ResetPasswordManager'

function ResetPasswordDesktop({
  t, userCallStatus, sendResetPasswordEmail, pwButtonisLoading, handleSubmit, isLoading,
  renderData, userObj
}) {
  return (
    <MarginWrapper>
      <LoadingIcon isLoading={pwButtonisLoading || userCallStatus === 'loading' || userCallStatus === 'failed' || userCallStatus === 'idle'}>
        <Grid container className={styles['reset-password-container']}>
          <Grid item xs={12} md={12} lg={12} xl={12} className={styles['reset-password-header']}>
            {isLoading && <LoadingIcon />}
            <SectionHeader text={t('button-reset-password')} size="large" />
          </Grid>
          {(!sendResetPasswordEmail)
            ? (
              ResetPasswordPage(t, handleSubmit, renderData, userObj)
            )
            : (
              ResetPasswordConfirm(t, handleSubmit)
            )}
        </Grid>
        <div className={styles['JNJ-footer']}>
          <JNJFooter />
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

ResetPasswordDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  userCallStatus: PropTypes.string.isRequired,
  sendResetPasswordEmail: PropTypes.bool.isRequired,
  pwButtonisLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userObj: PropTypes.shape({
    email: PropTypes.string
  }),
  renderData: PropTypes.func.isRequired
}

ResetPasswordDesktop.defaultProps = {
  userObj: ''
}

export default ResetPasswordDesktop
