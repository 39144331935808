import React from 'react'
import { formatPhoneToNational } from './phoneUtils'
import styles from '../pages/MyAccount/MyAccount.module.scss'

export function renderData(isMobile, label, value) {
  if (label === 'Phone') {
    value = formatPhoneToNational(value)
  }

  return (
    <div className={isMobile ? styles['mobile-data-row'] : styles['data-row']}>
      <strong className={isMobile ? styles['mobile-data-label'] : styles['data-label']}>{`${label}: `}</strong>
      <span className={isMobile ? styles['mobile-data-value'] : styles['data-value']}>{value || ' '}</span>
    </div>
  )
}
