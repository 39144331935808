import React from 'react'
import styles from '../MyAccount/MyAccount.module.scss'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import '../../util/i18n'
import ResetPasswordDesktop from './ResetPasswordDesktop'
import ResetPasswordMobile from './ResetPasswordMobile'
import { ResetPasswordManager } from './ResetPasswordManager'

export function ResetPassword() {
  const {
    isMobile,
    userCallStatus,
    userObj,
    t,
    sendResetPasswordEmail,
    pwButtonisLoading,
    handleSubmit
  } = ResetPasswordManager()

  const renderData = (label, value) => (
    <div>
      <span className={isMobile ? styles['mobile-data-label'] : styles['data-label']}>{`${label}: `}</span>
      <span
        className={
                isMobile ? styles['mobile-data-value'] : styles['data-value']
              }
      >
        {value || ' '}
      </span>
    </div>
  )

  const isLoading = pwButtonisLoading || userCallStatus === 'loading' || userCallStatus === 'idle'

  return (
    <MarginWrapper>
      {isMobileView() ? (
        <ResetPasswordMobile
          t={t}
          userObj={userObj}
          renderData={renderData}
          userCallStatus={userCallStatus}
          sendResetPasswordEmail={sendResetPasswordEmail}
          pwButtonisLoading={pwButtonisLoading}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      ) : (
        <ResetPasswordDesktop
          t={t}
          userObj={userObj}
          renderData={renderData}
          userCallStatus={userCallStatus}
          sendResetPasswordEmail={sendResetPasswordEmail}
          pwButtonisLoading={pwButtonisLoading}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
    </MarginWrapper>
  )
}

export default ResetPassword
