import React from 'react'
import PropTypes from 'prop-types'
import isMobileView from '../../util/MobileView'
import { AppCardDesktop } from './AppCardDesktop'
import { AppCardMobile } from './AppCardMobile'

export function AppCard(props) {
  const isMobile = isMobileView()

  return isMobile ? (
    <AppCardMobile
      name={props.name}
      imageUrl={props.imageUrl}
      onOpenButtonClick={props.onOpenButtonClick}
      onManageLinkClick={props.onManageLinkClick}
      onFavoriteClick={props.onFavoriteClick}
      secondaryLinkText={props.secondaryLinkText}
      buttonLinkText={props.buttonLinkText}
      showSecondaryLink={props.showSecondaryLink}
      showFavoriteIcon={props.showFavoriteIcon}
    />
  ) : (
    <AppCardDesktop
      name={props.name}
      imageUrl={props.imageUrl}
      onOpenButtonClick={props.onOpenButtonClick}
      onManageLinkClick={props.onManageLinkClick}
      onFavoriteClick={props.onFavoriteClick}
      secondaryLinkText={props.secondaryLinkText}
      buttonLinkText={props.buttonLinkText}
      favoriteLabelText={props.favoriteLabelText}
      showSecondaryLink={props.showSecondaryLink}
      showFavoriteIcon={props.showFavoriteIcon}
    />
  )
}

AppCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onOpenButtonClick: PropTypes.func,
  onManageLinkClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  secondaryLinkText: PropTypes.string,
  buttonLinkText: PropTypes.string,
  favoriteLabelText: PropTypes.string,
  showSecondaryLink: PropTypes.bool,
  showFavoriteIcon: PropTypes.bool
}

AppCard.defaultProps = {
  imageUrl: null,
  onOpenButtonClick: null,
  onManageLinkClick: null,
  onFavoriteClick: null,
  secondaryLinkText: '',
  buttonLinkText: '',
  favoriteLabelText: '',
  showSecondaryLink: false,
  showFavoriteIcon: false
}
