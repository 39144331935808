import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Grid from '@mui/system/Unstable_Grid/Grid'
import { useSelector } from 'react-redux'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import { UserProfile } from '../../features/user/UserProfile'
import { Button } from '../../features/section-components/Button'
import styles from './MyAccount.module.scss'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import '../../util/i18n'
import JNJFooter from '../../features/jnj-footer/JNJFooter'
import Alert from '../../features/error/Alert'
import { selectUserCallError, selectUserCallStatus } from '../../features/user/userSlice'
import { PictureUpload } from '../../features/PictureUpload/index'

function MyAccountDesktop({
  t, userObj, userProfilePictureData, fullName, isPageLoading, renderData,
  formatPhoneToNational, preferredLanguage, languageSelectorComponent, toggleLanguageSelector,
  showLanguageSelector, languageOptions, setAlertVisible, editAccountComponent,
  toggleEditAccount, showEditAccount, showProfileInfo, handlePhotoDelete
}) {
  const userError = useSelector(selectUserCallError)
  const userCallStatus = useSelector(selectUserCallStatus)
  const initials = `${userObj && userObj.firstName ? userObj.firstName[0] : ''}${userObj && userObj.lastName ? userObj.lastName[0] : ''}`

  return (
    <MarginWrapper>
      <LoadingIcon isLoading={isPageLoading || userCallStatus === 'failed'}>
        <div className={styles['parent-container']}>
          <div className={styles['my-account-container']}>
            <div className={styles['account-header']}>
              <SectionHeader text={t('AccountInfoHeader')} size="large" />
              {userError && (
              <div className={styles['alert-container']}>
                <Alert
                  message={t('error-saveFailed')}
                  onClose={() => setAlertVisible(false)}
                  alertStyle="error-alert"
                  headerText={t('error-title')}
                />
              </div>
              )}
            </div>
            <div className={styles['profile-info-container']}>
              <div className={styles['profile-picture-container']}>
                <div className={styles['profile-picture-wrapper']}>
                  <UserProfile
                    isInteractive={false}
                    profilePictureData={userProfilePictureData}
                    initials={initials}
                  />
                </div>
                <div className={styles['profile-picture-support-text']}>
                  {t('support-text-profile-pic')}
                  {userProfilePictureData ? (
                    <Button
                      text="Delete photo"
                      buttonStyle="cta-yellow-oval"
                      disabled={false}
                      icon={<DeleteOutlineIcon />}
                      iconSize="small"
                      onClick={handlePhotoDelete}
                    />
                  ) : null}
                </div>
                <PictureUpload />
              </div>
              <SectionHeader text={t('UserAccountInfoHeader')} size="medium" />
              {showProfileInfo && (
              <>
                {renderData(t('label-name'), fullName)}
                {renderData(t('label-email'), userObj.email)}
                {(userObj.secondEmail) && renderData(t('label-second-email'), userObj.secondEmail)}
                {(userObj.mobilePhone) && renderData(t('label-phone-num'), formatPhoneToNational(userObj.mobilePhone))}
              </>
              )}
              {!showEditAccount && (
              <div className={styles.buttons}>
                <Grid className={styles['manage-button']}>
                  <Button
                    text={t('button-edit-profile')}
                    buttonStyle="cta-grey-oval"
                    onClick={toggleEditAccount}
                  />
                </Grid>
              </div>
              )}
              {showEditAccount && editAccountComponent}
            </div>
            <div className="pref-lang-container">
              <SectionHeader text={t('label-header-pref-lang')} size="small" />
              {t('support-text-pref-lang')}
              {!showLanguageSelector && (
              <>
                {renderData(t('label-pref-lang'), languageOptions[preferredLanguage] || preferredLanguage)}
                <div className={styles.buttons}>
                  <Grid className={styles['manage-button']}>
                    <Button
                      text={t('label-edit-language')}
                      buttonStyle="cta-grey-oval"
                      onClick={toggleLanguageSelector}
                    />
                  </Grid>
                </div>
              </>
              )}
              {showLanguageSelector && (
              <div className={styles.buttons}>
                <Grid>
                  {languageSelectorComponent}
                </Grid>
              </div>
              )}
            </div>

            <div className="password-container" style={{ lineHeight: '1.5', marginTop: '40px' }}>
              <SectionHeader text={t('label-header-password')} size="small" />
              {t('support-text-pass', { email: userObj.email })}
              <div className={styles.buttons}>
                <Grid className={styles['manage-button']}>
                  <Link to="/reset-password" style={{ display: 'inline-block' }}>
                    <Button text={t('button-reset-password')} buttonStyle="cta-grey-oval" />
                  </Link>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['JNJ-footer']}>
          <JNJFooter />
        </div>
      </LoadingIcon>
    </MarginWrapper>
  )
}

MyAccountDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  userObj: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    secondEmail: PropTypes.string,
    mobilePhone: PropTypes.string,
    created: PropTypes.string,
    preferredLanguage: PropTypes.string
  }).isRequired,
  userProfilePictureData: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  renderData: PropTypes.func.isRequired,
  formatPhoneToNational: PropTypes.func.isRequired,
  preferredLanguage: PropTypes.string.isRequired,
  languageSelectorComponent: PropTypes.node.isRequired,
  toggleLanguageSelector: PropTypes.func.isRequired,
  showLanguageSelector: PropTypes.bool.isRequired,
  languageOptions: PropTypes.objectOf(PropTypes.string).isRequired,
  setAlertVisible: PropTypes.func.isRequired,
  editAccountComponent: PropTypes.element,
  toggleEditAccount: PropTypes.func.isRequired,
  showEditAccount: PropTypes.bool.isRequired,
  showProfileInfo: PropTypes.bool.isRequired,
  handlePhotoDelete: PropTypes.func.isRequired
}

MyAccountDesktop.defaultProps = {
  userProfilePictureData: '',
  editAccountComponent: null
}

export default MyAccountDesktop
