import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import { GATEWAY_API_LOGOUT, API_AUTH_COOKIE_NAME } from '../../util/config'
import { SectionHeader } from '../section-components/SectionHeader'
import JNJFooter from '../jnj-footer/JNJFooter'
import styles from './Logout.module.scss'
import isMobileView from '../../util/MobileView'
import { gatewayRequestAxios } from '../../api/interceptor'

export default function Logout() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = isMobileView()

  useEffect(() => {
    gatewayRequestAxios.get(GATEWAY_API_LOGOUT, {}).then(() => {
      localStorage.clear()
      sessionStorage.removeItem('logoutWarning')
      document.cookie = `${API_AUTH_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      navigate('/login')
    })
  }, [])

  return (
    <div className={styles['signout-container']}>
      <div className={styles['loading-icon']}>
        <CircularProgress />
      </div>
      <SectionHeader size="small" text={t('LoggingOut')} />
      {!isMobile && (
        <div className={styles['jnj-footer']}>
          <JNJFooter text={t('FooterText')} footerSize="medium" />
        </div>
      )}
    </div>
  )
}
