const fileToDataUrl = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    resolve(reader.result)
  }
  reader.onerror = () => reject(reader.error)
})

export const fileToBase64 = async (file) => {
  const dataUrl = await fileToDataUrl(file)
  const base64 = dataUrl.split(',')[1]

  return base64
}
