import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectUserObject,
  selectUserCallStatus,
  fetchUser,
} from '../../features/user/userSlice'
import {
  selectUserAppsObject,
  getUserAppsCallStatus,
  fetchUserApps,
} from '../../features/user-apps/UserAppsSlice'
import {
  selectProfilePictureImage,
  selectProfilePictureStatus,
  fetchProfilePicture
} from '../../features/user/profilePictureSlice'
import styles from './Dashboard.module.scss'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import { changePage, selectPage } from '../../features/side-menu/SideMenuSlice'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import '../../util/i18n'
import DashboardDesktop from './DashboardDesktop'
import DashboardMobile from './DashboardMobile'

export function Dashboard() {
  const { t } = useTranslation()
  const userObjData = useSelector(selectUserObject)
  const userObjStatus = useSelector(selectUserCallStatus)
  const userAppsData = useSelector(selectUserAppsObject)
  const userAppsStatus = useSelector(getUserAppsCallStatus)
  const userProfilePictureData = useSelector(selectProfilePictureImage)
  const userProfilePictureStatus = useSelector(selectProfilePictureStatus)
  const dispatch = useDispatch()
  const currPage = useSelector(selectPage)
  const isMobile = isMobileView()
  const mobileIdentifier = `mobile-${isMobile}`

  useEffect(() => {
    if (currPage !== '/') {
      dispatch(changePage('/'))
    }
  }, [])

  useEffect(() => {
    if (userObjStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userObjStatus, dispatch])

  useEffect(() => {
    if (userAppsStatus === 'idle') {
      dispatch(fetchUserApps())
    }
  }, [userAppsStatus, dispatch])

  useEffect(() => {
    if (userProfilePictureStatus === 'idle') {
      dispatch(fetchProfilePicture())
    }
  }, [userProfilePictureStatus, dispatch])

  const appGridIsLoading = userAppsStatus === 'loading' || userAppsStatus === 'idle'
  const isPageLoading = !((userObjStatus === 'succeeded' || userObjStatus === 'failed') && (userProfilePictureStatus === 'succeeded' || userProfilePictureStatus === 'failed'))

  return (
    <MarginWrapper>
      <div className={styles['dashboard-page']}>
        <div id={mobileIdentifier} />
        <LoadingIcon isLoading={isPageLoading}>
          {isMobile ? (
            <DashboardMobile
              t={t}
              userObjData={userObjData}
              userProfilePictureData={userProfilePictureData}
              userAppsData={userAppsData}
              appGridIsLoading={appGridIsLoading}
            />
          ) : (
            <DashboardDesktop
              t={t}
              userObjData={userObjData}
              userProfilePictureData={userProfilePictureData}
              userAppsData={userAppsData}
              appGridIsLoading={appGridIsLoading}
            />
          )}
        </LoadingIcon>
      </div>
    </MarginWrapper>
  )
}

export default Dashboard
