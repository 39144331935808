import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from '../../util/auth'

const AuthGuard = (WrappedComponent) => {
  function AuthenticatedComponent(props) {
    if (!isAuthenticated) {
      localStorage.setItem('redirect', window.location.pathname)
      return <Navigate to="/login" />
    }

    // we will be passing in several component properties here
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />
  }

  return AuthenticatedComponent
}

export default AuthGuard
