import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import pageReducer from '../features/side-menu/SideMenuSlice'
import userReducer from '../features/user/userSlice'
import userAppsReducer from '../features/user-apps/UserAppsSlice'
import errorReducer from '../features/error/errorSlice'
import healthCheckReducer from '../features/health/healthCheckSlice'
import profilePictureReducer from '../features/user/profilePictureSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    page: pageReducer,
    user: userReducer,
    userApps: userAppsReducer,
    error: errorReducer,
    healthCheck: healthCheckReducer,
    profilePicture: profilePictureReducer,
  },
})
