import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export function useFileUploadManager(acceptedFileTypes, onFile, resetUploadedFiles) {
  const [uploadedFiles, setUploadedFiles] = useState([])

  useEffect(() => {
    if (resetUploadedFiles) {
      setUploadedFiles([])
    }
  }, [resetUploadedFiles])

  const handleFileDrop = (acceptedFiles) => {
    setUploadedFiles(acceptedFiles)
    if (onFile) {
      onFile(acceptedFiles)
    }
  }

  const {
    getRootProps,
    inputRef
  } = useDropzone({ onDrop: handleFileDrop, accept: acceptedFileTypes })
  const rootProps = getRootProps()

  const identifier = `file-upload-${Date.now()}-${Math.random()}`

  return {
    uploadedFiles,
    handleFileDrop,
    rootProps,
    inputRef,
    identifier
  }
}
