import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogContent, DialogActions, IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { Button } from '../section-components/Button'
import { ImageDisplayBox } from '../section-components/ImageDisplayBox'
import { FileUploadMobile } from '../section-components/FileUpload/FileUploadMobile'
import Alert from '../error/Alert'
import styles from './PictureUpload.module.scss'
import { useFileSelectedHandler, useSaveProfilePictureHandler, useCancelHandler } from './PictureUploadManager'

export function PictureUploadMobile({ onFileUpload }) {
  const { t } = useTranslation()
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const fileInputRef = useRef(null)

  const handleFileSelected = useFileSelectedHandler(setFile, setError, fileInputRef)
  const handleSaveProfilePicture = useSaveProfilePictureHandler(
    file, onFileUpload, setDialogOpen, setError)
  const handleCancel = useCancelHandler(setFile, setError, setDialogOpen)

  const fileUploadClass = error ? `${styles.fileUploadBoxErrorMobile}` : styles.fileUploadBoxMobile

  return (
    <div>
      <Button
        text={t('pfp-upload-button-text-mobile')}
        buttonStyle="cta-grey-oval"
        variant="contained"
        onClick={() => setDialogOpen(true)}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        PaperProps={{ className: styles.dialogContainerMobile }}
        style={{ zIndex: 10 }}
      >
        <div className={styles.closeButtonWrapperMobile}>
          <IconButton className={styles.closeButtonMobile} onClick={handleCancel} aria-label="close">
            <CloseIcon className={styles.closeIconMobile} style={{ fontSize: '20px' }} />
          </IconButton>
          <p className={styles.closeTextMobile}>{t('pfp-close-text')}</p>
        </div>
        <DialogContent className={styles.dialogContentMobile}>
          <p className={styles.uploadTextMobile}>{t('pfp-upload-upload-photo-text-mobile')}</p>
          {error && (
          <div className={styles.alertContainerMobile}>
            <Alert
              headerText={t('incompatible-file')}
              message={t('pfp-try-again-error')}
              alertStyle="error-alert"
              onClose={() => setError('')}
              showClose={false}
              blurb={(
                <p>
                  {t('pfp-image-size-error')} <br />
                  {t('pfp-file-type-error')} <br />
                  {t('pfp-size-limit-error')}
                </p>
                                )}
            />
          </div>
          )}
          {!file && (
            <div className={fileUploadClass}>
              <FileUploadMobile
                key={error ? 'reset-upload' : 'upload'}
                ref={fileInputRef}
                image
                acceptedFileTypes="image/*"
                onFile={handleFileSelected}
                text1={t('drag-and-drop-text-mobile')}
                linkText={t('browse-text-mobile')}
                text2={t('to-upload-text-mobile')}
              />
            </div>
          )}
          {file && !error && (
            <div className={styles.imageDisplayContainerMobile}>
              <ImageDisplayBox image={URL.createObjectURL(file)} square="200px" />
            </div>
          )}
          <DialogActions className={styles.dialogActionsMobile}>
            <div className={styles.buttonContainerMobile}>
              <div className={styles.saveButtonMobile}>
                <Button
                  text={t('pfp-save-text')}
                  buttonStyle="cta-red-oval"
                  onClick={handleSaveProfilePicture}
                  disabled={!file || error}
                />
              </div>
              <div className={styles.cancelButtonMobile}>
                <Button
                  text={t('button-cancel')}
                  buttonStyle="cta-grey-oval"
                  onClick={handleCancel}
                />
              </div>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

PictureUploadMobile.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
}
