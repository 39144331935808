import React from 'react'
import PropTypes from 'prop-types'
import isMobileView from '../../util/MobileView'
import { PictureUploadDesktop } from './PictureUploadDesktop'
import { PictureUploadMobile } from './PictureUploadMobile'

export function PictureUpload(props) {
  const isMobile = isMobileView()
  return isMobile ? (
    <PictureUploadMobile onFileUpload={props.onFileUpload} />
  ) : (
    <PictureUploadDesktop onFileUpload={props.onFileUpload} />
  )
}

PictureUpload.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
}
