import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchUser,
  selectUserObject,
  selectUserCallStatus,
  selectUserCallError,
  updateUser,
} from '../../features/user/userSlice'
import {
  selectProfilePictureStatus,
  fetchProfilePicture,
  selectProfilePictureImage,
  deleteProfilePicture,
} from '../../features/user/profilePictureSlice'
import styles from './MyAccount.module.scss'
import { LoadingIcon } from '../../features/section-components/LoadingIcon'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import MyAccountMobile from './MyAccountMobile'
import MyAccountDesktop from './MyAccountDesktop'
import { MyAccountManager } from './MyAccountManager'
import { LanguageSelectionManager } from '../../features/language-selector/LanguageSelectionManager'
import { EditAccountManager } from '../../features/EditAccount/EditAccountManager'
import { renderData } from '../../util/renderData'
import { formatPhoneToNational } from '../../util/phoneUtils'

export function MyAccount() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = isMobileView()

  const userCallStatus = useSelector(selectUserCallStatus)
  const userObj = useSelector(selectUserObject)
  const userCallError = useSelector(selectUserCallError)
  const userProfilePictureStatus = useSelector(selectProfilePictureStatus)
  const userProfilePictureData = useSelector(selectProfilePictureImage)

  const handlePhotoDelete = async () => {
    dispatch(deleteProfilePicture())
  }

  const {
    isPageLoading,
    fullName,
    preferredLanguage,
    languageSelectorComponent,
    setAlertVisible,
    showLanguageSelector,
    toggleLanguageSelector,
    languageOptions,
    editAccountComponent,
    showEditAccount,
    showProfileInfo,
    toggleEditAccount,
  } = MyAccountManager({
    dispatch,
    t,
    userCallStatus,
    userObj: userObj || {},
    userCallError,
    userProfilePictureStatus,
    userProfilePictureData,
    fetchUser,
    fetchProfilePicture,
    LanguageSelectionManager,
    EditAccountManager,
    updateUser,
    handlePhotoDelete
  })

  if (userCallError && userCallError.message) {
    return (
      <div className={styles['error-message']}>
        <p>Error: {userCallError.message}</p>
      </div>
    )
  }

  const renderDataWithMobileCheck = (label, value) => renderData(isMobile, label, value)

  return (
    <MarginWrapper>
      <LoadingIcon isLoading={isPageLoading}>
        {isMobile ? (
          <MyAccountMobile
            t={t}
            userObj={userObj || {}}
            userProfilePictureData={userProfilePictureData}
            fullName={fullName}
            isPageLoading={isPageLoading}
            isMobile={isMobile}
            renderData={renderDataWithMobileCheck}
            preferredLanguage={preferredLanguage}
            showLanguageSelector={showLanguageSelector}
            toggleLanguageSelector={toggleLanguageSelector}
            languageSelectorComponent={languageSelectorComponent}
            languageOptions={languageOptions}
            setAlertVisible={setAlertVisible}
            editAccountComponent={editAccountComponent}
            toggleEditAccount={toggleEditAccount}
            showEditAccount={showEditAccount}
            showProfileInfo={showProfileInfo}
            formatPhoneToNational={formatPhoneToNational}
            handlePhotoDelete={handlePhotoDelete}
          />
        ) : (
          <MyAccountDesktop
            t={t}
            userObj={userObj || {}}
            userProfilePictureData={userProfilePictureData}
            fullName={fullName}
            isPageLoading={isPageLoading}
            isMobile={isMobile}
            renderData={renderDataWithMobileCheck}
            preferredLanguage={preferredLanguage}
            languageSelectorComponent={languageSelectorComponent}
            showLanguageSelector={showLanguageSelector}
            toggleLanguageSelector={toggleLanguageSelector}
            languageOptions={languageOptions}
            setAlertVisible={setAlertVisible}
            editAccountComponent={editAccountComponent}
            toggleEditAccount={toggleEditAccount}
            showEditAccount={showEditAccount}
            showProfileInfo={showProfileInfo}
            formatPhoneToNational={formatPhoneToNational}
            handlePhotoDelete={handlePhotoDelete}
          />
        )}
      </LoadingIcon>
    </MarginWrapper>
  )
}

export default MyAccount
