import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@mui/material/Button'
import styles from './Button.module.scss'

const useFocus = () => {
  const [focusMode, setFocusMode] = useState(false)

  const handleFocus = () => {
    setFocusMode(true)
  }

  const handleBlur = () => {
    setFocusMode(false)
  }
  return { focusMode, handleFocus, handleBlur }
}

export function Button({
  text, disabled, onClick, buttonStyle, icon, iconSize
}) {
  const { focusMode, handleFocus, handleBlur } = useFocus()
  const buttonClass = (buttonStyle === 'cta-red-oval' || buttonStyle === 'cta-red-oval-small') && focusMode
    ? `${styles[`button-${buttonStyle}`]} ${styles.focused}`
    : styles[`button-${buttonStyle}`]

  const iconClass = iconSize === 'small' ? styles['icon-small'] : styles['icon-default']

  return (
    <div className={buttonClass}>
      <MuiButton variant="outlined" disabled={disabled} onClick={onClick} onFocus={handleFocus} onBlur={handleBlur} startIcon={icon && (<span className={iconClass}>{icon}</span>)}>
        {text}
      </MuiButton>
    </div>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.string,
  icon: PropTypes.node,
  iconSize: PropTypes.string
}

Button.defaultProps = {
  buttonStyle: 'default',
  disabled: false,
  onClick: () => {},
  icon: null,
  iconSize: 'default'
}
