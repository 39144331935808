import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingIcon } from '../section-components/LoadingIcon'
import MarginWrapper from '../wrappers/MarginWrapper'
import isMobileView from '../../util/MobileView'
import EditAccountMobile from './EditAccountMobile'
import EditAccountDesktop from './EditAccountDesktop'
import { EditAccountContainer } from './EditAccountContainer'

export function EditAccount() {
  const isMobile = isMobileView()
  const { t } = useTranslation()

  const {
    isPageLoading,
    showAlert,
    alertMessage,
    showEditAccount,
    toggleEditAccount,
    handleSubmit,
    userFields,
    validFields,
    validateChange,
    handleBlur,
    isFormValid,
    TextMaskCustom,
    setUserFields,
    userProfilePictureData,
  } = EditAccountContainer({ t })

  return (
    <MarginWrapper>
      {showAlert && <div className="alert-message">{alertMessage}</div>}
      <LoadingIcon isLoading={isPageLoading}>
        {isMobile ? (
          <EditAccountMobile
            t={t}
            userFields={userFields}
            validFields={validFields}
            validateChange={validateChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            isFormValid={isFormValid}
            TextMaskCustom={TextMaskCustom}
            userProfilePictureData={userProfilePictureData}
            toggleEditAccount={toggleEditAccount}
            showEditAccount={showEditAccount}
            setUserFields={setUserFields}
            isMobile={isMobile}
          />
        ) : (
          <EditAccountDesktop
            t={t}
            userFields={userFields}
            validFields={validFields}
            validateChange={validateChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            isFormValid={isFormValid}
            TextMaskCustom={TextMaskCustom}
            userProfilePictureData={userProfilePictureData}
            toggleEditAccount={toggleEditAccount}
            showEditAccount={showEditAccount}
            setUserFields={setUserFields}
            isMobile={isMobile}
          />
        )}
      </LoadingIcon>
    </MarginWrapper>
  )
}

export default EditAccount
