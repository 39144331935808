import React from 'react'
import { FaPlus } from 'react-icons/fa'
import PropTypes from 'prop-types'
import styles from './UserProfile.module.scss'
import isMobileView from '../../util/MobileView'
import staticProfilePicture from '../../assets/JJ Icon Web Profile.png'

export function UserProfile(props) {
  const isMobile = isMobileView()

  const initialsProfilePictureColor = (font, background) => (
    font || background ? { color: font, backgroundColor: background } : {}
  )

  const getContainerClass = () => {
    if (props.small) {
      // When small prop is true, return the smallContainer class
      return styles.smallImageContainer
    }
    // Default to the regular container class when small prop is not true
    return isMobile ? `${styles.container} ${styles['profilepicture-mobile-view']}` : styles.container
  }

  // Use a function to get the appropriate class based on props.small
  const getImageClass = () => {
    if (props.small) {
      // When small prop is true, return only the smallImage class
      return styles.smallImage
    }
    // Default to img class when small prop is not true
    return styles.img
  }

  const imageClass = getImageClass()
  const containerClass = getContainerClass()

  return (
    <div className={containerClass}>
      {(() => {
        if (props.profilePictureData) {
          return (
            <img
              className={imageClass}
              src={props.profilePictureData}
              alt={props.initials}
            />
          )
        }

        if (!props.profilePictureData && props.initials) {
          return (
            <div
              className={imageClass}
              style={initialsProfilePictureColor(props.fontColor, props.backgroundColor)}
            >
              {props.initials}
            </div>
          )
        }

        return (<img className={imageClass} src={staticProfilePicture} alt="DefaultProfile" />)
      })()}
      {props.isInteractive && (
        <div className={styles['add-icon']} data-testid="fa-plus-icon">
          <FaPlus onClick={props.onClick} />
        </div>
      )}
    </div>
  )
}

UserProfile.propTypes = {
  isInteractive: PropTypes.bool,
  onClick: PropTypes.func,
  profilePictureData: PropTypes.string,
  initials: PropTypes.string,
  fontColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  small: PropTypes.bool
}

UserProfile.defaultProps = {
  isInteractive: true,
  onClick: () => {},
  profilePictureData: '',
  initials: '',
  fontColor: '',
  backgroundColor: '',
  small: false
}

export default UserProfile
