import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '../../features/section-components/Button'
import styles from './ResetPassword.module.scss'
import Alert from '../../features/error/Alert'
import {
  fetchUser, selectUserCallStatus, selectUserObject
} from '../../features/user/userSlice'
import { gatewayRequestAxios } from '../../api/interceptor'
import { GATEWAY_API_RESET_PASSWORD } from '../../util/config'
import isMobileView from '../../util/MobileView'
import '../../util/i18n'

export const ResetPasswordManager = () => {
  const dispatch = useDispatch()
  const isMobile = isMobileView()
  const userCallStatus = useSelector(selectUserCallStatus)
  const userObj = useSelector(selectUserObject)
  const { t } = useTranslation()
  const [sendResetPasswordEmail, setSendResetPasswordEmail] = useState(false)
  const [pwButtonisLoading, setPwButtonisLoading] = useState(false)

  useEffect(() => {
    if (userCallStatus === 'idle') {
      dispatch(fetchUser())
    }
  }, [userCallStatus, dispatch])

  const handleSubmit = () => {
    setPwButtonisLoading(true)
    gatewayRequestAxios.post(GATEWAY_API_RESET_PASSWORD)
      .then(() => {
        setSendResetPasswordEmail(true)
        setPwButtonisLoading(false)
      })
      .catch(() => setPwButtonisLoading(false))
  }
  return {
    isMobile,
    userCallStatus,
    userObj,
    t,
    sendResetPasswordEmail,
    pwButtonisLoading,
    handleSubmit
  }
}

export function ResetPasswordPage(t, handleSubmit, renderData, userObj) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className={styles['reset-password-confirmation-container']}>
      {userObj && userObj.email && (
      <p className={styles['reset-password-message']}>
        {renderData(t('support-text-reset-password'), <b>{userObj.email}.</b>)}
      </p>
      )}
      <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className={styles['reset-password-buttons']}>
        <Button text={t('button-send')} buttonStyle="cta-red-oval" onClick={handleSubmit} icon={<EmailOutlinedIcon className={styles['email-icon']} />} />
        <Link to="/home" style={{ textDecoration: 'none' }}><Button text={t('button-no-reset')} buttonStyle="filled-grey-oval" /></Link>
      </Grid>
    </Grid>
  )
}

export function MobileResetPasswordPage(t, handleSubmit, renderData, userObj) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className={styles['reset-password-confirmation-container']}>
      {userObj && userObj.email && (
      <p className={styles['mobile-reset-password-message']}>
        {renderData(t('support-text-reset-password'), <b>{userObj.email}.</b>)}
      </p>
      )}
      <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className={styles['mobile-reset-password-buttons']}>
        <Button text={t('button-send')} buttonStyle="cta-red-oval" onClick={handleSubmit} icon={<EmailOutlinedIcon className={styles.icon} />} />
        <Link to="/home" style={{ textDecoration: 'none' }}><Button text={t('button-no-reset')} buttonStyle="filled-grey-oval" /></Link>
      </Grid>
    </Grid>
  )
}

export function MobileResetPasswordConfirm(t, handleSubmit) {
  return (
    <Grid className={styles['mobile-reset-password-confirmation-container']}>
      <Alert
        message={(
          <div
            onClick={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit()
              }
            }}
            role="button"
            tabIndex={0}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('success-email-link')}
          </div>
                  )}
        blurb={t('success-email-message')}
        alertStyle="success-alert"
        headerText={t('success-email-header')}
        showClose={false}
      />
    </Grid>
  )
}
export function ResetPasswordConfirm(t, handleSubmit) {
  return (
    <Grid className={styles['reset-password-confirmation-container']}>
      <Alert
        message={(
          <div
            onClick={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit()
              }
            }}
            role="button"
            tabIndex={0}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('success-email-link')}
          </div>
                    )}
        blurb={t('success-email-message')}
        alertStyle="success-alert"
        headerText={t('success-email-header')}
        showClose={false}
      />
    </Grid>
  )
}
