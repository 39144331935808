import React from 'react'
import PropTypes from 'prop-types'
import MarginWrapper from '../../features/wrappers/MarginWrapper'
import { Button } from '../../features/section-components/Button'
import { SectionHeader } from '../../features/section-components/SectionHeader'
import styles from './Health.module.scss'

function HealthDesktop({
  t, status, healthCheck, error, refresh, handleRefresh, ServerStatusIndicator, content
}) {
  const leftAlignStyle = {
    textAlign: 'left',
    fontFamily: 'JohnsonDisplay',
    marginLeft: '100px'
  }

  return (
    <MarginWrapper>
      <div style={leftAlignStyle}>
        <SectionHeader text={t('healthHeader')} size="large" />
        <SectionHeader text={t('health-api-header')} size="small" />
        <hr className={styles.topLine} />
        <ServerStatusIndicator status={status} healthCheck={healthCheck} t={t} />
        {content}
        {status === 'failed' && <p>Error: {error}</p>}
        <div style={{ textAlign: 'left', paddingTop: '10px' }}>
          <Button onClick={handleRefresh} disabled={refresh} text={t('health-refresh-api-button')} buttonStyle="cta-grey-oval" />
        </div>
      </div>
    </MarginWrapper>
  )
}

HealthDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  healthCheck: PropTypes.shape({
    idp: PropTypes.bool,
    status: PropTypes.bool,
    uptime: PropTypes.string,
    error: PropTypes.string
  }).isRequired,
  error: PropTypes.string,
  refresh: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  ServerStatusIndicator: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
}

HealthDesktop.defaultProps = {
  error: null
}

export default HealthDesktop
