import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './SectionHeader.module.scss'
import isMobileView from '../../util/MobileView'

/* Scenario: SectionHeader renders
Given the PageHeader component is rendered to the screen
Then it will display large bolded text
And the text value is configurable as a property
And the component offers three sizes, [Large, Medium, Small]
that are set as a property */

export function SectionHeader(props) {
  const container = useRef(null)
  const mobileView = isMobileView()

  return (
    <div ref={container} className={mobileView ? styles['section-header-mobile'] : null}>
      <h1
        className={styles[`section-header-size-${props.size}`]}
      >
        {props.text}
      </h1>
    </div>
  )
}

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'unbold']),
}

SectionHeader.defaultProps = {
  size: 'large',
}
