import React, { useState } from 'react'
import i18n from '../../util/i18n'
import LanguageSelector from './LanguageSelector'
import { updateUserLanguage } from '../user/userSlice'
import isMobileView from '../../util/MobileView'

export const LanguageSelectionManager = ({ dispatch, userObj, t }) => {
  const [showLanguageSelector, setShowLanguageSelector] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(userObj.preferredLanguage ?? '')
  const [setAlertVisible] = useState(false)
  const [setAlertMessage] = useState('')

  const toggleLanguageSelector = () => setShowLanguageSelector(!showLanguageSelector)

  const handleLanguageChange = (event) => setSelectedLanguage(event.target.value)

  const isMobile = isMobileView()
  const languageOptions = {
    'en-US': t('language-english'),
    'es-ES': t('language-spanish'),
  }

  const handleLanguageSelectorError = (errorMessage) => {
    setAlertMessage(errorMessage)
    setAlertVisible(true)
  }

  const handleSave = () => {
    const languageUpdatePayload = { preferredLanguage: selectedLanguage }

    dispatch(updateUserLanguage(languageUpdatePayload))
      .unwrap()
      .then(() => {
        i18n.changeLanguage(selectedLanguage)
        setShowLanguageSelector(false)
      })
      .catch(() => {
        handleLanguageSelectorError(t('error-saveFailed'))
      })
  }

  const languageSelectorComponent = showLanguageSelector && (
  <LanguageSelector
    label={t('pref-lang-header')}
    value={selectedLanguage}
    onChange={handleLanguageChange}
    onSave={handleSave}
    onCancel={() => toggleLanguageSelector()}
    saveButtonText={t('button-save-changes')}
    cancelButtonText={t('button-cancel')}
    buttonStyles={{
      saveButton: 'cta-red-oval-edit-profile',
      cancelButton: 'cta-grey-oval'
    }}
    languageOptions={languageOptions}
    onError={handleLanguageSelectorError}
    isMobile={isMobile}
  />
  )

  return {
    languageSelectorComponent,
    setAlertVisible,
    showLanguageSelector,
    toggleLanguageSelector,
    languageOptions
  }
}
