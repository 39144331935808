/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import OutputIcon from '@mui/icons-material/Output'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { UserProfile } from '../user/UserProfile'
import { formatDate } from '../user/UserInfo'
import { decodeHtml } from '../../util/decodeHTML'
import styles from './HamburgerMenu.module.scss'
import logo from '../../assets/JNJOneAccess-logo-red.svg'

export default function HamburgerMenu(props) {
  const { t } = useTranslation()
  const formattedLoginDate = formatDate(props.userInfo.lastLogin)

  return (
    <div id="side-menu" className={props.isHidden ? styles.hidden : styles.open}>
      <div className={styles.header}>
        <img className={styles.logo} src={logo} alt="JnJ One Access Logo" />
        <IconButton sx={{ margin: '2% 6% 0 auto', padding: '0', display: 'block' }} onClick={props.sideMenuClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </div>
      {props.userInfo && props.userInfo.firstName ? (
        <div className={styles.profile}>
          <UserProfile
            profilePictureData={props.userInfo.profilePictureData ? props.userInfo.profilePictureData : ''}
            initials={props.userInfo.firstName && props.userInfo.lastName ? `${props.userInfo.firstName[0]}${props.userInfo.lastName[0]}` : 'NN'}
            isInteractive={false}
            small
          />
          <div className={styles.userInfo}>
            <span className={styles.userName}>
              {props.userInfo.firstName && props.userInfo.lastName ? `${props.userInfo.firstName.toUpperCase()} ${props.userInfo.lastName.toUpperCase()}` : 'UKNOWN USER'}
            </span>
            <span className={styles.loginDate}>
              {props.userInfo.lastLogin ? decodeHtml(t('last-login', { lastLogin: formattedLoginDate })) : ''}
            </span>
          </div>
        </div>
      ) : (<></>)}
      <>
        <List>
          {props.menuLinks[0].map((link) => (
            <ListItem key={link.label} disablePadding className={styles.menuItem}>
              <Link to={link.path} className={styles.menuItemLink} onClick={() => { props.sideMenuClose() }}>
                <ListItemText
                  disableTypography
                  sx={{ padding: '0px 0px 0px 22px' }}
                  className={styles.menuItemText}
                  primary={t(link.label)}
                />
              </Link>
            </ListItem>
          ))}
        </List>
        <hr className={styles.sectionbreak} />
        <List>
          {props.menuLinks[1].map((link) => (
            <ListItem key={link.label} disablePadding>
              <Link
                to={link.path}
                className={styles.menuItemLink}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  padding: '10px 0px 0px 22px'
                }}
                onClick={() => { props.sideMenuClose() }}
              >
                <OutputIcon className={styles.logoutIcon} />
                <ListItemText
                  disableTypography
                  sx={{ padding: '0px 0px 0px 10px' }}
                  className={styles.menuItemText}
                  primary={t(link.label)}
                />
              </Link>
            </ListItem>
          ))}
        </List>
      </>
    </div>
  )
}

HamburgerMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }))).isRequired,
  userInfo: PropTypes.shape({
    profilePictureData: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    lastLogin: PropTypes.string,
  }),
  isHidden: PropTypes.bool.isRequired,
  sideMenuClose: PropTypes.func,
}

HamburgerMenu.defaultProps = {
  sideMenuClose: () => { },
  userInfo: null
}
