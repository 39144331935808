import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styles from './UserInfo.module.scss'
import isMobileView from '../../util/MobileView'
import '../../util/i18n'

export function formatDate(dateString) {
  try {
    if (!dateString) {
      throw new Error('Invalid time value')
    }

    const date = new Date(dateString)
    const options = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    return new Intl.DateTimeFormat('en-US', options).format(date)
  } catch (err) {
    return ''
  }
}

export function UserInfo(props) {
  const { t } = useTranslation()
  const memberDate = new Date(props.dateOfMembership)
  const formattedLoginDate = formatDate(props.lastLoginScenario)
  const mobileView = isMobileView()

  return (
    <div className={mobileView ? `${styles['mobile-user-info']}` : styles['user-info']}>
      <p className={mobileView ? `${styles['mobile-user-info-p']}` : styles['user-info-p']}>
        {t('member-since')} {memberDate.getFullYear()}
      </p>
      <p className={mobileView ? `${styles['mobile-user-info-p']}` : styles['user-info-p']}>
        {t('user-status')}: {t(props.status)}
      </p>
      <p className={mobileView ? `${styles['mobile-user-info-p']}` : styles['user-info-p']}>
        {t('last-login')}: {formattedLoginDate} {props.lastLoginLocation}
      </p>
    </div>
  )
}

UserInfo.propTypes = {
  dateOfMembership: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['status-active', 'status-inactive', 'ACTIVE', 'INACTIVE']).isRequired,
  lastLoginScenario: PropTypes.string.isRequired,
  lastLoginLocation: PropTypes.string
}

UserInfo.defaultProps = {
  lastLoginLocation: null
}
