import { createSlice } from '@reduxjs/toolkit'

export const pageSlice = createSlice({
  name: 'page',
  initialState: {
    prevValue: '/',
    value: window.location.pathname,
  },
  reducers: {
    changePage: (state, action) => {
      if (action.payload !== state.value) {
        state.prevValue = state.value
      }
      state.value = action.payload
    },
  },
})

export const { changePage } = pageSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPage = (state) => state.page.value
export const selectPrevPage = (state) => state.page.prevValue

export default pageSlice.reducer
