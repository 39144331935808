import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const formatPhoneToNational = (phone) => {
  if (typeof phone !== 'string' || !phone) {
    return phone
  }
  const phoneNumber = parsePhoneNumberFromString(phone, 'US')
  return phoneNumber ? phoneNumber.formatNational() : phone
}

export function convertPhoneToE164(phone) {
  if (typeof phone !== 'string' || !phone || (phone && phone.trim() === '')) {
    return phone
  }
  const cleaned = phone.replace(/\D/g, '') // Removes non-digit characters
  return `+1${cleaned}`
}

export const validatePhoneNumber = (phone) => /^\(\d{3}\) \d{3}-\d{4}$/.test(phone)
