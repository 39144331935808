import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import LaunchIcon from '@mui/icons-material/Launch'
import { Button } from '../section-components/Button'
import styles from './AppCard.module.scss'
import { renderImage } from '../../util/image/renderImage'

export function AppCardDesktop({
  name,
  imageUrl,
  onOpenButtonClick,
  onManageLinkClick,
  onFavoriteClick,
  secondaryLinkText,
  buttonLinkText,
  favoriteLabelText,
  showSecondaryLink,
  showFavoriteIcon
}) {
  return (
    <div className={styles.appCardDesktop}>
      <div className={styles.appLogoContainer}>{renderImage(imageUrl, name, styles)}</div>
      <div className={styles.cardContent}>
        {showFavoriteIcon && favoriteLabelText && onFavoriteClick && (
          <div className={styles.favoriteContainer}>
            <span className={styles.favoriteText}>{favoriteLabelText}</span>
            <IconButton className={styles.favoriteIcon} onClick={onFavoriteClick} aria-label="Favorite">
              <FavoriteBorderIcon />
            </IconButton>
          </div>
        )}
        <div className={styles.appName}>{name}</div>
      </div>
      <div className={styles.actionLinks}>
        {showSecondaryLink && secondaryLinkText && onManageLinkClick && (
          <button type="button" className={styles.manageLink} onClick={onManageLinkClick}>
            {secondaryLinkText}
          </button>
        )}
        {buttonLinkText && onOpenButtonClick && (
          <Button
            text={buttonLinkText}
            buttonStyle="app-card-desktop"
            onClick={onOpenButtonClick}
            icon={<LaunchIcon />}
          />
        )}
      </div>
    </div>
  )
}

AppCardDesktop.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onOpenButtonClick: PropTypes.func,
  onManageLinkClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  secondaryLinkText: PropTypes.string,
  buttonLinkText: PropTypes.string,
  favoriteLabelText: PropTypes.string,
  showSecondaryLink: PropTypes.bool,
  showFavoriteIcon: PropTypes.bool
}

AppCardDesktop.defaultProps = {
  imageUrl: null,
  onOpenButtonClick: null,
  onManageLinkClick: null,
  onFavoriteClick: null,
  secondaryLinkText: '',
  buttonLinkText: '',
  favoriteLabelText: '',
  showSecondaryLink: false,
  showFavoriteIcon: false
}
