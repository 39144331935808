import React from 'react'
import PropTypes from 'prop-types'
import styles from './ApplicationGrid.module.scss'
import { AppCard } from '../AppCard'

export function ApplicationGridMobile({
  applications, noAppsMsg, onClick, buttonLinkText
}) {
  const gridClass = styles['mobile-application-grid']
  const noAppsMessageClass = styles['mobile-no-applications-message']

  return (
    <div className={gridClass}>
      {applications.length ? (
        applications.map((app) => (
          <AppCard
            key={app.appId}
            name={app.label}
            imageUrl={app.src}
            onOpenButtonClick={() => onClick(app.link)}
            buttonLinkText={buttonLinkText}
            onFavoriteClick={() => onClick()}
          />
        ))
      ) : (
        <div className={noAppsMessageClass}> {noAppsMsg} </div>
      )}
    </div>
  )
}

ApplicationGridMobile.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.shape({
    appId: PropTypes.string,
    src: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string.isRequired,
  })),
  noAppsMsg: PropTypes.string,
  onClick: PropTypes.func,
  buttonLinkText: PropTypes.string,
}

ApplicationGridMobile.defaultProps = {
  applications: [],
  noAppsMsg: 'NoAppsMsg',
  onClick: () => {},
  buttonLinkText: '',
}
