import React from 'react'
import PropTypes from 'prop-types'

export function ImageDisplayBox(props) {
  return (
    <div>
      <img src={props.image} alt={props.description} width={props.square} height={props.square} style={{ objectFit: 'cover' }} />
    </div>
  )
}

ImageDisplayBox.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string,
  square: PropTypes.string
}

ImageDisplayBox.defaultProps = {
  description: '',
  square: '200px'
}
